// ----------- header -----------
.header {
  //box-shadow: 2px 2px 12px #00000014;
  .header__items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1.5rem calc(100vw / 20);
    position: relative;
    align-items: flex-start;
    .logo {

      //width: 200px;
      //padding: 5px 5px 5px 25px;
      //.small{
      //  width: 120px;
      //}

      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
        color: #414040;
        //font-family: 'Ballet', cursive;
        //font-family: 'Merienda', cursive;
        //font-family: 'Monsieur La Doulaise', cursive;
        .wrap-logo {
          //padding: 0 0 0 10px;
        }

        h2 {
          font-size: 30px;
          margin: 0;
          //letter-spacing: 3px;
        }

        p {
          text-transform: uppercase;
          font-size: 12px;

        }
      }
    }
  }
}

@media (max-width: 640px) {
  .header .header__items {
    padding: .85rem calc(100vw / 20);

    .logo a {
      font-size: 35px;
    }
  }
}

@media (max-width: 400px) {
  .header .header__items {

    .logo a {
      h2 {
        font-size: 20px;
      }
      p{
        font-size: 10px;
      }
    }

  }
}

// ----------- navbar -----------
.navbar {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  position: relative;
  a{
    color: #2a3137;
    margin-bottom: 20px;
    &:hover{
      color: #f2233f;
    }
  }
  .navbar__language-theme{
    display: flex;
  }
  .path-restaurant {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;

    .path-restaurant__boetfort {
      a {
        text-decoration: none;
      }

    }

    .path-restaurant__grimbergen {
      margin: 0 20px;

      a {
        text-decoration: none;
      }
    }
  }

  .internalization-toggle {
    width: 30px;
    cursor: pointer;
    display: none;

    .container-language {
      padding: 0 0 0 8px;

      span {
        display: inline-block;
        margin-bottom: 3px;
      }
    }

    .dropdown-menu {
      position: absolute;
      top: 69px;
      right: -40px;
      bottom: auto;
      left: auto;
      z-index: 10;
      display: flex;
      flex-direction: column;
      transform: translate(-40px, 0px);
      border: solid 1px #454545;
      border-radius: 30px;
      cursor: pointer;
      box-shadow: 2px 2px 5px #00000038;

      &::after {
        content: '';
        width: 10px;
        height: 10px;
        display: block;
        position: absolute;
        z-index: 11;
        //border: 0;
        border-left: 1px solid #454545;
        border-top: 1px solid #454545;
        transform: rotate(45deg);
        margin-left: -2px;
        left: 50%;
        top: -6px;
        background-color: #fdfdfe;
      }

      .dropdown-menu__button_top {
        padding: 8px 20px 6px;
        border: none;
        background-color: #fdfdfe;
        border-top-right-radius: 30px;
        border-top-left-radius: 30px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        cursor: pointer;
        font-weight: 600;
        font-size: 18px;
        color: #2c2c2c;
      }

      .dropdown-menu__button_middle {
        padding: 6px 20px 6px;
        border: none;
        background-color: #fdfdfe;
        border-radius: 0;
        cursor: pointer;
        font-weight: 600;
        font-size: 18px;
        color: #2c2c2c;
      }

      .dropdown-menu__button_bottom {
        padding: 4px 20px 8px;
        border: none;
        background-color: #fdfdfe;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border-bottom-right-radius: 30px;
        border-bottom-left-radius: 30px;
        cursor: pointer;
        font-weight: 600;
        font-size: 18px;
        color: #2c2c2c;
      }

      .active_button {
        color: #7e7e7e;
      }
    }

    .display-none {
      display: none;
    }
  }

  .sidebar-toggle, .theme-toggle {
    margin-left: 25px;
    cursor: pointer;
  }
}

@media (max-width: 555px) {
  .navbar {
    .path-restaurant {
      display: none;
    }

    .internalization-toggle {
      display: block;
    }

    .sidebar__internalization-toggle {
      display: none;
    }
  }
}

// ----------- overlay -----------
.overlay {
  height: calc(100vh - 62.4px - 48px);
  width: 100%;
  z-index: 5;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
}

@media (max-width: 640px) {
  .overlay {
    height: calc(100vh - 81.6px);
  }
}

.overlay-none {
  display: none;
}

// ----------- sidebar -----------
.sidebar {
  background-color: #060b26;
  //width: 250px;
  //height: 100vh;
  position: fixed;
  //top: 56.6px;
  right: -100%;
  transition: 0.75s;
  z-index: 6;
  top: 0;
  bottom: 0;

  .sidebar__wrap {
    position: relative;
    height: calc(100vh - 0px);
    width: 380px;
    padding: 8rem 5rem;
    background-color: #fdfdfe;
    //border-top: solid 1px #0f172a47;
    display: flex;
    flex-direction: column;

    a {
      margin: 5px 0;
      box-sizing: content-box;
      cursor: pointer;
      text-decoration: none;
      //width: 380px;

      h3 {
        color: #111111;
        margin: 0;
      }

    }


  }
}

.sidebar__internalization-toggle {
  //padding: 25px 0 0;
  button {
    border: none;
    background: transparent;
    cursor: pointer;
    margin-left: 10px;
  }

  .active {
    color: #747474;
  }
}

#sidebar-toggle {
  width: 20px;
  height: 21px;
  position: relative;
  //margin: 30px auto;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  z-index: 200;
}

#sidebar-toggle span {
  display: block;
  position: absolute;
  height: 1px;
  width: 100%;
  background: #363636;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

#sidebar-toggle span:nth-child(1) {
  top: 0;
}

#sidebar-toggle span:nth-child(2) {
  top: 7px;
}

#sidebar-toggle span:nth-child(3) {
  top: 7px;
}

#sidebar-toggle span:nth-child(4) {
  top: 14px;
}

#sidebar-toggle.open span:nth-child(1) {
  top: 7px;
  width: 0%;
  left: 50%;
}

#sidebar-toggle.open span:nth-child(2) {
  transform: rotate(45deg);
}

#sidebar-toggle.open span:nth-child(3) {
  transform: rotate(-45deg);
}

#sidebar-toggle.open span:nth-child(4) {
  top: 7px;
  width: 0%;
  left: 50%;
}

.active {
  right: 0;
  transition: 0.4s;
  //transition-timing-function: cubic-bezier(0.1, 0.7, 1.0, 0.1);
}

.sidebar .sidebar__wrap a h3.active-link {
  color: #7e7e7e;
}

@media (max-width: 440px) {
  .sidebar {
    width: 100%;

    .sidebar__wrap {
      position: relative;
      height: calc(100vh - 0px);
      padding: 130px 30px 0;
      background-color: #fdfdfe;
      //border-top: solid 1px #0f172a47;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: auto;

      a h3 {
        //font-size: 25px;
      }

      a h3.active-link {
        border-left: none;
        padding-left: 0;
        //border-bottom: solid 3px #a292c3;
      }
    }
  }
}

// ----------- end sidebar -----------