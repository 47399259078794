.contact {
  display: block;
  max-width: 400px;
  margin: 0 auto 80px;
  position: relative;

  img.strawberry_left {
    position: absolute;
    right: 450px;
    bottom: -20px;
    width: 150px;
  }

  img.strawberry_right {
    position: absolute;
    left: 450px;
    bottom: -20px;
    width: 150px;
  }

  img.contact.citrus_r{
    display: none;
  }

  h2 {
    text-align: center;
  }

  .contact_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .contact_block {
      span {
        margin-right: 10px;
      }

      a {
        color: #767676;

        &:hover {
          color: #e7c705;
        }
      }
    }

    .contact_address {
      p {
        margin: 0;
      }
    }
  }
}

@media (max-width: 825px){
  .contact img.strawberry_left, .contact img.strawberry_right{
    display: none;
  }
}

@media (max-width: 450px){
  .contact .contact_content{
    //flex-direction: column;
    justify-content: space-around;
    .contact_block{
      margin-bottom: 10px;
    }
    .contact_address{
      //display: flex;
      p{
        margin-right: 10px;
      }
    }
  }
}