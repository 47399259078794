.main{
  overflow-y: hidden;
  .restaurant-top{
    position: relative;
    .restaurant-top__items{
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 2;

      img{
        width: auto;
        height: 300px;
        z-index: 1;
      }
      h1{
        //font-family: 'Ballet', cursive;
        //font-family: 'Merienda', cursive;
        font-family: 'Monsieur La Doulaise', cursive;
        font-size: 40px;
        font-weight: 300;
      }
    }
    &:after{
      content: '';
      position: absolute;
      top: 0;
      width: 100vw;
      height: 400px;
      background: #fff;
      border-bottom-left-radius: 100px;
      border-bottom-right-radius: 100px;
      z-index: 1;
      box-shadow:  20px 20px 47px #bebebe,
      -20px -20px 47px #ffffff;
    }
  }
  .restaurant-menu{
    margin-top: 50px;
    position: relative;
    //padding: 1.5rem calc(100vw / 20);
    .restaurant-menu__items{

      width: 100%;
      padding: 1.5rem calc(100vw / 20);
      z-index: 2;
      .restaurant-menu__item{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        span{
          font-size: 20px;
          display: block;
          margin-bottom: 20px;
        }
        .menu-tabs-buttons{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          flex-wrap: wrap;
          .menu-tab__button{
            padding: .5rem 1rem;
            background: #ffffff;
            margin: 5px;
            border: solid 1px #dadada;
            border-radius: 20px;
            cursor: pointer;
            box-shadow:  6px -6px 12px #cbcbcb,
            -6px 6px 12px #ffffff;
          }
          .active{
            background: #bea8b1;
          }

        }
        .menu-tab__items{
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          flex-wrap: wrap;
          margin-top: 20px;
          //width: 100vw;

          .menu-tab__content{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100px;
            height: 100px;
            background: #ececec;
            border-radius: 50%;
            margin: 5px;
            box-shadow:  6px -6px 12px #cbcbcb,
            -6px 6px 12px #ffffff;

          }
          .block{
            display: block;
          }


        }
      }
    }
    &:before{
      content: '';
      position: absolute;
      top: 0;
      width: 90vw;
      height: 1600px;
      background: #ffffff;
      border-top-right-radius: 50px;
      border-top-left-radius: 50px;
      z-index: 1;
      box-shadow:  39px -39px 78px #d7d7d7,
      -39px 39px 78px #ffffff;
    }
  }
}
@media (max-width: 400px){
  .main{
    .restaurant-top{
      .restaurant-top__items{
        img{
          width: auto;
          height: 150px;
        }
        h1{
          //font-family: 'Ballet', cursive;
          //font-family: 'Merienda', cursive;
          font-family: 'Monsieur La Doulaise', cursive;
          font-size: 40px;
          font-weight: 300;
        }
      }
      &:after{
        content: '';
        position: absolute;
        top: 0;
        width: 100vw;
        height: 260px;
        background: #fff;
        border-bottom-left-radius: 100px;
        border-bottom-right-radius: 100px;
        z-index: 1;
        box-shadow: 20px 20px 47px #bebebe, -20px -20px 47px #ffffff;
      }
    }
  }
}
// ============= test-grid ==============

.test-grid{

}