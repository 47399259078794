.ant-modal-title{
  text-align: center;
}

.ant-modal-content{
  display: flex;
  flex-direction: column;
  #name, #email{
    width: 80%;
    margin-bottom: 10px;
  }
  #message{
    margin-bottom: 10px;
  }
  #btn{
    width: 150px;
    font-size: 20px;
    font-weight: 600;
    color: #505c66;
    //height: auto;
    border: solid 1px #505c66;

    padding: 3px 10px;
    background-color: #ffffff;
    cursor: pointer;
    &:hover{
      background-color: #395ee6;
      color: #ffffff;
      border: solid 1px #395ee6;
    }
  }
}
.ant-modal-footer{
  display: none;
}