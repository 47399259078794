.main {
  overflow-x: hidden;
}

.home-top {
  h1{
    font-size: 28px;
  }
  //background-image: url('../images/home/food/39.png');
  position: relative;
  max-width: 1170px;
  height: 526px;
  background-size: contain;
  background-repeat: no-repeat;
  .home-top__items {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    position: absolute;
    //top: 50px;

    .home-top__item {
      margin: 5px;

      a {
        text-decoration: none;
        color: #000;
      }
      .home-top__item_text {
        text-align: center;
      }
    }
  }
}

button.btn-to-order {
  position: absolute;
  bottom: 50px;
  font-size: 20px;
  font-weight: 700;
  color: #505c66;
  //height: auto;
  border: solid 2px #505c66;
  border-radius: 30px;
  padding: 7px 25px;
  background-color: #ffffff;
  cursor: pointer;

  &:hover {
    background-color: #74991a;
    color: #ffffff;
    border: solid 2px #74991a;
  }
}

.img_19{
  width: 80px;
  position: relative;
  transform: rotate(145deg);
  right: 250px;
}
@media (max-width: 1070px){
  .home-top {
    h1{
      font-size: 26px;
    }
    height: 570px;
    margin-bottom: 50px;
    top: 85px;
    .home-top__items {
      top: -40px;
    }
    .btn-to-order{
      bottom: 90px;
    }
  }
}
@media (max-width: 920px){
  .home-top {
    //h1{
    //  font-size: 18px;
    //}
    height: 570px;
    margin-bottom: 50px;
    top: 85px;
    .home-top__items {
      top: -40px;
    }
    .btn-to-order{
      bottom: 150px;
    }
  }
  //section.our-services{
  //  margin-top: 130px;
  //}
}
@media (max-width: 840px) {
  .home-top {
    //h1{
    //  font-size: 18px;
    //}
    height: 370px;
    margin-bottom: 50px;
    top: 85px;
    .home-top__items {
      top: -40px;
    }
    .btn-to-order{
      bottom: 10px;
    }
  }
  section.our-services{
    margin-top: 130px;
  }
}

@media (max-width: 690px) {
  .home-top {
    h1{
      font-size: 22px;
    }
    height: 300px;
    margin-bottom: 50px;
    top: 95px;
    .home-top__items {
      top: -50px;
    }
    .btn-to-order{
      bottom: 10px;
    }
  }
  section.our-services{
    margin-top: 130px;
  }
}

@media (max-width: 390px) {
  .home-top {
    //background-image: url('../images/home/food/41.png');
    h1{
      font-size: 18px;
    }
    height: 280px;
    margin-bottom: 50px;
    top: 100px;
    .home-top__items {
      top: -70px;
    }
    .btn-to-order{
      bottom: 30px;
    }
  }
  section.our-services{
    margin-top: 130px;
  }
}

//@media (max-width: 320px) {
//  .home-top {
//    .home-top__items {
//      flex-direction: column;
//      align-items: center;
//
//      .home-top__item {
//        //padding: 1.5rem calc(100vw / 20);
//
//
//      }
//    }
//    .home-top__image{
//      img {
//        width: 30px;
//        height: auto;
//      }
//    }
//
//  }
//}

//-------- our-services ---------
.our-services {
  h2{
    text-align: center;
    margin-bottom: 30px;
  }
  .our-services__wrap {
    display: flex;
    flex-direction: column;
    width: 100%;

    .items {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px;

      .item {
        width: 49%;
        height: 400px;
        background: #ffffff;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);

        img {
          width: 90%;
          margin: 0 auto;
        }

        button {
          background: #ffffff;
          padding: 5px 15px;
          font-weight: 700;

          &:hover {
            background-color: #74991a;
            color: #ffffff;
            border: solid 2px #74991a;
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .our-services {
    .our-services__wrap {
      .items {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px;

        .item {
          width: 100%;
          height: 400px;
          //background: #e5e4e4;
          padding: 10px;
          margin-bottom: 10px;
        }
      }
    }
  }
}

//-------- end our-services ---------
.what_is_work {
  h2{
    text-align: center;
    margin-bottom: 50px;
    //color: #000;
  }
  width: auto;
  height: auto;
  //background-size: contain;
  background-repeat: no-repeat;
  .timeline-text{
    width: 210px;
  }
}

@media (max-width: 620px) {
  .what_is_work {
    //background-image: url('../images/home/spice_7.jpg');
    height: 2250px;
  }
}
@media (max-width: 400px) {
  .what_is_work {
    height: 1750px;
  }
}

//-------- how is work ---------
.ant-timeline-item-content {
  display: flex;
  flex-direction: row;

  .timeline-text {
    margin-right: 20px;
    color: white;

    p {
      margin: 0;
      color: #000;
    }
    .timeline-text__phone a{
      color: #6c910c;
    }
  }
}

@media (max-width: 620px) {
  .ant-timeline-item-content {
    display: flex;
    flex-direction: column;
    img {
      margin-top: 20px;
    }
    .timeline-text {
      margin-right: 20px;
      color: white;

      p {
        margin: 0;
      }
    }
  }
}

@media (max-width: 400px) {
  .ant-timeline-item-content {
    padding-left: 10px;
    img {
      margin-top: 10px;
      width: 200px;
      height: auto;
    }
  }
}

//-------- end how is work ---------

input#name, input#email{
  border-radius: 0;
  border: solid 1px #767676;
}

.our_chefs{
  h2{
    text-align: center;
    margin-bottom: 50px;
  }
}
.ant-modal-content #btn:hover{
  background: #74991a!important;
}