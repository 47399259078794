:root {
  --primary: #23292e;
  --secondary: #ffffff;
  --light: #ffffff;
}

.input {
  width: 2.75rem;
  height: 1.75rem;
  padding: 0.25rem;
  margin: 0 0.75rem;
  background-color: transparent;
  border: 1px solid black;
  border-radius: 5px;
  text-align: center;
  color: black;
}

.menu-calculator {
  padding: 2rem 2.25rem 1rem;
  margin: 0 auto;
  max-width: 50rem;
  //background-color: var(--light);
  //box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
}

@media (min-width: 660px) {
  .menu-calculator {
    display: grid;
    grid-gap: 4rem;
    grid-template-columns: 1fr 1fr;
  }
}

.menu-item {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

//.logo-wrap {
//  width: 200px;
//  display: flex;
//  flex-direction: column;
//  align-items: center;
//  text-decoration: none;
//  color: #414040;
//  grid-column: 1 / 3;
//}
//h2 {
//  font-size: 30px;
//  margin: 0;
//}
//p {
//  text-transform: uppercase;
//  font-size: 12px;
//}
//.logo {
//  width: 200px;
//  margin: 0 auto 2rem;
//  display: block;
//  grid-column: 1 / 3;
//}
//
//@media (min-width: 660px) {
//  .logo {
//    margin-bottom: 0;
//  }
//}

.mains {
  grid-column: 1 / 2;
}

.mains-name {
  margin: 0;
  flex-grow: 1;
}

.mains-description {
  flex: 1 1 100%;
  margin: 0.75rem 0 0;
  color: black;
}

.aside {
  grid-column: 2 / 3;
}

.extras {
  color: black;
}

.extras + .extras {
  margin-top: 1.5rem;
}

.mains-price, .extras-price{
  margin-left: 3px;
}

@media (min-width: 660px) {
  .extras + .extras {
    margin-top: 2.5rem;
  }
}

.extras .menu-item {
  margin-bottom: 0.75rem;
}

.extras-heading {
  margin: 0 0 1.5rem;
  border-bottom: 1px solid black;
}

.extras-name {
  flex-grow: 1;
}

.total {
  grid-column: 1 / 3;
  margin-top: 2.5rem;
  padding: 0.75rem 0 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: sticky;
  bottom: 0;
  color: black;
  //background-color: var(--light);
  border-top: 1px solid black;
  //box-shadow: 0 -0.5rem 0.75rem 0.5rem var(--light);
}

@media (min-width: 660px) {
  .total {
    margin-top: 0;
  }
}

.total-title {
  margin: 0;
}

.total-price {
  font-size: 1.5rem;
}
