// ---------- change theme -----------
.dark {

  .header {
    background: #23292e;
  }

  div.container-language {
    color: #e5e7eb;
  }

  .sidebar .wrap {
    background-color: #23292e;

    h3 {
      color: #e5e7eb;
    }

  }

  .dropdown-menu {
    border: solid 1px #e5e7eb;

    button {
      background-color: #23292e;
      color: #e5e7eb;
    }

    .active_button {
      color: #a292c3;
    }
  }

  .dropdown-menu::after {
    border-left: 1px solid #e5e7eb;
    border-top: 1px solid #e5e7eb;
    background-color: #23292e;
  }

  main {
    background-color: #23292e;
    color: #e5e7eb;

    .address__phone__email {

      a {
        text-decoration: none;
        color: #f4f4f4;

        &:hover {
          color: #8b5fe3;
        }
      }
    }
  }

  //.field input{
  //  background-color: #698bbf;
  //  //color: #f4f4f4;
  //  :focus{
  //    background-color: #1d2839;
  //  }
  //  :active{
  //    background-color: #1d2839;
  //  }
  //}
  .field input,
  .field input:-webkit-autofill,
  .message textarea,
  .message textarea:-webkit-autofill,
  select:-webkit-autofill {
    background-color: #d0c9dc;

    &, &:hover, &:focus, &:active {
      -webkit-box-shadow: 0 0 0px 1000px #d0c9dc inset !important;
    }
  }

  //.message textarea{
  //  background-color: #1d2839;
  //  color: #f4f4f4;
  //}
  .field button {
    background-color: #5c3a97;
    color: #f4f4f4;
  }

  ::placeholder {
    color: #454545;
  }

  .middle-about .middle-about__items .middle-about__item {
    background-color: #322a3f;
  }

}
// =============== add ==============
.dark{
  .navbar .internalization-toggle .dropdown-menu::after{
    background: #23292e;
  }
  .navbar .internalization-toggle .dropdown-menu .dropdown-menu__button_top,
  .navbar .internalization-toggle .dropdown-menu .dropdown-menu__button_middle,
  .navbar .internalization-toggle .dropdown-menu .dropdown-menu__button_bottom{
    background: #23292e;
    color: #fff;
  }
  .navbar .internalization-toggle .dropdown-menu .active_button{
    color: #7e7e7e;
  }
  .home-top .home-top__items .home-top__item a{
    color: #fff;
  }
  .sidebar .sidebar__wrap{
    background: #23292e;
    a h3{
      color: #fff;
    }
  }
  .sidebar__internalization-toggle button{
    color: #fff;
  }
  .sidebar__internalization-toggle .active {
    color: #8f8f8f;
  }
  #sidebar-toggle span{
    background: #ffffff;
  }
  .header .header__items .logo a{
    color: #ffffff;
  }
  .main .restaurant-top:after{
    background: #23292e;
    box-shadow:  33px 33px 66px #1c2125,
    -33px -33px 66px #2a3137;
  }
  .main .restaurant-menu:before{
    background: #23292e;
    box-shadow:  33px -33px 66px #1c2125,
    -33px 33px 66px #2a3137;
  }
  .main .restaurant-menu .restaurant-menu__items .restaurant-menu__item .menu-tabs-buttons .menu-tab__button{
    border: solid 1px #33393e;
    background: #23292e;
    box-shadow:  6px -6px 12px #1c2125,
    -6px 6px 12px #2a3137;
  }
  .main .restaurant-menu .restaurant-menu__items .restaurant-menu__item .menu-tabs-buttons .active{
    background: #53606b;
  }
  .main .restaurant-menu .restaurant-menu__items .restaurant-menu__item .menu-tab__items .menu-tab__content{
    background: #23292e;
    box-shadow:  6px -6px 12px #1c2125,
    -6px 6px 12px #2a3137;
  }
  h1, h2, footer, .navbar a {
    color: white;
  }
  .navbar a {
    color: white;
    &:hover{
      color: #f2233f;
    }
  }
  .our-services .our-services__wrap .items, b, strong{
    color: #1e2124;
  }
  h3, p, span, div, strong{
    color: white;
  }
  .ant-timeline-item-content .timeline-text p{
    color: white;
  }
  .input{
    border: 1px solid #ffffff;
    color: #ffffff;
  }
  .input::placeholder {
    color: #ffffff;
  }
  .total{
    border-top: 1px solid #ffffff;
  }
  .extras-heading {
    border-bottom: 1px solid #ffffff;
  }
  #root > div > main > div > section.our-services > div > div > div > button{
    color: black;
  }
  .our-services .our-services__wrap .items .item{
    background: #393c3e;
  }
  .ant-modal-header #rcDialogTitle0, .ant-modal-close-x span, .ant-modal-body label, .ant-modal-content #message,
  .ant-modal-content #email, .ant-modal-body #name, .ant-modal-body > p{
    color: black;
  }
  #root > div > main > div > section.contact > div > div.contact_block > div.contact_phone > a,
  #root > div > main > div > section.contact > div > div.contact_block > div.contact_email > a{
    color: #8f8f8f;
    &:hover{
      color: #e7c705;
    }
  }
  .contact_address span{
    color: #8f8f8f;
  }
}
